.navbar.navbar-bbg {
	background: darken($brand-blue-dk,5%);
	border-top: 1px solid darken($brand-blue-dk,8%);
	margin-bottom: 0;
	text-transform: capitalize;

	li > a {
		color: #fff;
		border-top: 1px solid darken($brand-blue-dk,8%);

		&:hover,&:focus,&:active {
			background: darken($brand-blue-dk,8%);
		}
	}

	@media only screen and (max-width: $screen-sm) {

	li:last-child a {
		border-bottom: 1px solid darken($brand-blue-dk,8%);
	}

	}



	.open > a {
		background: darken($brand-blue-dk,8%);
		border-color: darken($brand-blue-dk,8%);
	}

	.dropdown-menu {
		padding-top: 0;
		padding-bottom: 0;

		li > a {
			background: darken($brand-blue-dk,8%);
			border: 0;
			padding: 10px 15px 10px;

			i {
				margin-right: 5px;
			}
		}
	}

	button {
		color: #fff;
	}

}

.navbar-nav {
	margin-top: 0;
}



@media only screen and (min-width: $screen-sm) {

	.navbar.navbar-bbg {
		background: inherit;
		border: 0;

		li > a {
			border: none;
			&:hover,&:active, &:focus {
				background: none;
			}
		}

		.open > a {
			background: none;
			border-color: transparent;
		}

		.dropdown-menu {
			border: 0;

			li >a {
				background: $brand-blue-dk;

				&:hover,&:active,&:focus {
					background: darken($brand-blue-dk,8%);
				}
			}
		}

	}

}

// Center navigation for medium size screens

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
	.navbar.navbar-bbg {
		text-align: center;
	}
	.navbar-right {
		float: none !important;
		display: inline-block;
	}
}
