.bbg-form {
	small {
		font-weight: 200;
		font-size: 1.1rem;
		color: #444;
	}

	.form-group {
		margin-bottom: 2rem;
	}

	input,textarea {
		font-size: 16px;
	}
}


.bbg-form-contact {
	@include callout;
	padding: 3rem;
	max-width: 800px;
	margin: 6rem auto;

	.submit{
		text-align: center;
		.btn-bbg-blue {
			padding: 1rem 5rem;
			margin-top: 3rem;
		}
	}

}