@charset 'utf-8';

// Variables

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic);
@import '//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css';
@import 'variables';
@import 'bootstrap-resets';

// Import bootstrap from bower components
@import '../lib/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../lib/bourbon/app/assets/stylesheets/bourbon';
@import '../lib/animate-sass/animate';


// mixins
@import 'mixins';

// components
@import 'components/buttons';
@import 'components/header';
@import 'components/nav';
@import 'components/content-block';
@import 'global';
@import 'components/hero-banner';
@import 'components/video-banner';
@import 'components/services-list';
@import 'components/home-relationships';
@import 'components/home-services';
@import 'components/forms';
@import 'components/footer';
@import 'components/cta';
@import 'components/client-list';

// pages
@import 'pages/home';
@import 'pages/page';
@import 'pages/team';
@import 'pages/relationships';
@import 'pages/contact';
// Shame
@import 'shame';
