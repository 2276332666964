.team-list {
	@include bbg-wrapper;

	h2 > a {
		color: #000;
	}

	.team-member {
		@include make-row();
		margin-bottom: 6rem;
		border-top: 1px solid lighten($brand-blue-lt,10%);
		margin-top: 3rem;
		padding-top: 3rem;

		article {
			@include make-sm-column(8);

		}

		aside {
			@include make-sm-column(4);

			img {
				width: 90%;
				border-radius: $brand-radius;
				//border: 5px solid #fff;
				//@include transform(rotate(-3deg));
				margin: 1.5rem auto;
				display: block;
				//box-shadow: 0 2px 5px 0 rgba(#000,.3);
			}

		}
	}

}


.team-links {
	margin-bottom: 1.5rem;
	display: block;
	a {
		display: inline-block; margin-right: 5px;
		&:hover {
			border: none;
		}
	}
	img {
		height: 44px;
		width: 44px;
	}
}
.team-questions {
	dd {
		margin-bottom: 1rem;
	}

	dl {
		border-top: 1px solid $brand-blue-lt;
		padding-top: 1.5rem;
		margin-top: 2rem;
		@include display(flex);
		@include flex-wrap(wrap);
		div {
			@include flex(0 0 50%);
			padding-right: 1rem;
			padding-bottom: 1rem;
		}
	}
}

.testimonial {
	border-left: 5px solid $brand-blue-lt;
	background: rgba($brand-blue-lt,.1);
	margin-top: 2.5rem;
	font-size: 1.8rem;
	font-weight: 200;
	padding: 2rem;

	small {
		&:before {
			content: "\2014 \00a0";
		}
	}
}
