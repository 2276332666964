.partners-list {
	padding-top: 3rem;
	@include bbg-wrapper;
	li {
		@include make-xs-column(6);
		@include make-sm-column(4);
		@include make-lg-column(3);
		margin-bottom: 30px;
		a {
			display: block;
			padding: 1rem;
			border: 2px solid rgba($brand-blue-lt,.5);

			img {

			}
		}

	}
}
