.content-block {
	padding:  6rem 2rem;
	text-align: center;

	h2 {
		font-weight: 700;
	}

	h2,h3,h4,h5,h6 {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}


	&.banner {
		background: {
			color: lighten($brand-blue-lt,10%);
			image: url('../img/link-pattern.png');
			border-bottom: 1px solid $brand-blue-lt;
			position: center center;
		}

		.service-page-cfo-services & {

		}

		.service-page-business-advisory & {
			background-image: url('../img/circle-pattern.png');
		}

		.service-page-our-team & {
			background-image: url('../img/dots-pattern.png');
		}

		.service-page-bookkeeping-accounting & {
			background-image: url('../img/diagonal-pattern.png');
		}

		.service-page-controller-services & {
			background-image: url('../img/diagonal-pattern.png');
		}

		.service-page-relationships & {
			background-image: url('../img/starburst-pattern.png');
		}

		.service-page-contact & {
			background-image: url('../img/swirl-pattern.png');
		}


		h2 {
			background: $brand-blue-lt;
			display: inline-block;
			padding: 1.5rem;
			text-transform: capitalize;
			border-radius: $brand-radius;
		}

		h4 {
			font-size: 2rem;
		}
	}

	&.cta {
		margin-top: 6rem;
		border-top: 1px solid $brand-action;
		background: {
			color: lighten($brand-action,10%);
		}

		h2 {
			padding: 1.5rem;
			text-transform: capitalize;
		}
	}
}
