.client-list-container {
  @include bbg-wrapper;
  margin-top: 6rem;
}

.client-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.client-list-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  @media only screen and (min-width: $screen-sm) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33%;
  }
  background: transparent;

  aside {
    margin: .5rem;
  }
  button {
    appearance: none;
    display: block;
    width: 100%;
    background: transparent;
    margin: 0;
    padding: 1rem;
    box-sizing: border-box;
    outline: none;
    border: none;
    vertical-align: middle;
    border: 4px solid rgba($brand-blue-lt,.15);
    transition: all 75ms ease-in;
    box-shadow: none;
    border-radius: 4px;
    img {
      max-width: 100%;
    }
    &:hover {
      border: 4px solid rgba($brand-blue-lt,.35);
      box-shadow: 0 5px 15px #eee;
    }
  }
}

.client-modal {
  .modal-header {
    background: $brand-blue-dk;
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    button {
      appearance: none;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 2rem;
      display: inline-block;
    }
    h4 {
      font-weight: 400;
      font-size: 3.5rem;
      flex: 1 1 auto;
    }
    a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .modal-body {
    font-size: 1.8rem;
    padding: 1.5rem;
    @media only screen and (min-width: $screen-md) {
      padding: 3rem;
    }
  }
}
