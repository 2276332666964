.contact {

.content-column {
	margin-top: 6rem;
	p {
		font-size: 1.8rem;
		text-align: center;
	}
}
	
}

