@mixin btn-bbg($background: $brand-blue-lt,$text: #fff ) {
	@extend .btn-primary;
	background: darken($background,10%);
	border: none;
	color: $text !important;
	font-weight: bold;
	text-transform: capitalize;
	@include transition(background, 100ms ease-in);
	&:hover {
		background: darken($background,15%);
		color: $text;
	}
}

@mixin bbg-wrapper {
	width: 85%;
	max-width: 800px;
	margin: 0 auto;

	@media only screen and (min-width: 1100px) {
		max-width: 960px;
		font-size: 1.6rem;
	}
}

@mixin callout {
	background: lighten($brand-blue-lt,10%);
	border: 1px solid lighten($brand-blue-lt,5%);
	border-radius: $brand-radius;
}

@mixin bbg-headers {
	font-size: 2.2rem;
	font-weight: bold;
	margin: 3rem 0 1rem;
}