.home-relationships{
	background: lighten($brand-blue-lt,10%);
	margin-top: 3rem;
	@include clearfix();
}

.case-studies {
	@include bbg-wrapper;

	> section {
		text-align: center;
		@include make-xs-column(12);
		@include make-sm-column(6);
	}

	h1 {
		margin: 0;
		padding: 2rem 0;
		background: $brand-blue-dk;
		text-transform: capitalize;
		font-size: 2rem;
		a {
			color: #fff;
		}
	}


	.card {
		background: #fff;
		padding: 1.5rem;
		margin-bottom: 3rem;
		img {
			width: 70%;
			margin-bottom: 1.5rem;
		}
	}

	.text {
		min-height: 120px;
	}
}
