.business-services-list {
	@include make-row();
	list-style-type: none;
	padding-left: 0;

	a:hover{
		text-decoration: none;

	}


	li {
		@include make-md-column(4);
		text-align: center;
		margin-bottom: 5rem;

		div[class^="service-"], div[class*=" service-"] {
			background: lighten($brand-blue-lt,15%);
			padding: 1.5rem;

			&:before {
				content:"";
				background-color: $brand-blue-dk;
				background-image: url('../img/icon-services.png');
				background-position: 0 0;
				background-size: auto 70px;
				height: 70px;
				width: 70px;
				border-radius: 100%;
				display: block;
				margin: -35px auto 0;
			}

		}

		div.service-business-advisory:before {
			background-position: -210px 0;
		}

		div.service-controller-services:before {
			background-position: -70px 0;
		}

		div.service-accounting-bookkeeping:before {
			background-position: -140px 0;
		}


		h3 {
			font-weight: bold;
			font-size: 1.8rem;
			text-transform: capitalize;

			a {
				color: $blk;
			}
		}

		p {
			a {
				color: $brand-tan-dk;
				text-transform: capitalize;
				font-weight: bold;
			}
		}
	}
}
