.btn-bbg-blue {
	@include btn-bbg;
	font-weight: normal;
}


.btn-bbg-tan {
	@include btn-bbg($brand-tan-dk,#fff);
	font-weight: normal;
}

.btn-bbg-action {
	@include btn-bbg($brand-action,#fff);
	font-weight: normal;
}
