footer {
	background: $brand-blue-dk;
	margin-top: 3rem;
	color: #fff;
	padding: 1.5rem;
	text-align: center;
	text-transform: capitalize;

	small {
		display: block;
		margin-top: 1rem;
	}

	a,a:hover {
		color: $brand-blue-lt;
	}
}


.cta + footer {
	margin-top: 0;
}
