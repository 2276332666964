.hero-banner {
	text-align: center;
	background:{
		image: url('../img/boardwalk-business-banner.jpg');
		position: center center;
		repeat: no-repeat;
		size: cover;
	}

	h1 {
		margin: 0;
		padding: 12rem 2rem;

		strong {
			display: block;
		}
	}

	@media only screen and (min-width: $screen-sm) {
		h1 {
			padding: 18rem 0;
		}
	}
}