.alert-banner {
	background: $brand-action;
	padding: 1rem;
	span {
		display: block;
		font-size: 1.6rem;
		text-align: center;

		a {
			font-weight: 800;
			display: inline-block;
			padding: .5rem .75rem;
			border: 1px solid #fff;
			background: $brand-action;
			color: #fff;
			border-radius: 4px;
			&:hover,&:active,&:focus {
				text-decoration: none;
				background: #fff;
				color: $brand-action;
			}
		}
	}
}


header.masthead {
	padding-top: 1.5rem;
	background: $brand-blue-dk;

	i {
		color: $brand-blue-lt;
	}

	.cta {
		text-align: center;
		margin: 1.5rem 15px;

		@media only screen and (min-width: $screen-sm) {
			display: none;
		}
	}

	.container {
		padding-left: 0;
		padding-right: 0;
	}

}

.meta-logo {
	@include make-md-column(5);
	text-align: center;

	a {
		display: block;
	}

	img {
		width: 90%;
		max-width: 400px;
		margin: 0 auto 2rem;
		display: block;
	}
}

.nav-wrapper {
	@include make-md-column(7);
	padding-left: 0;
	padding-right: 0;
}




//
// .meta-nav {
// 	@include make-row();
//
// 	.meta-logo {
// 		@include make-sm-column(6);
// 		@include make-md-column(4);
// 		text-align: center;
//
// 		a {
// 			display: inline;
// 		}
//
// 		img {
// 			width: 90%;
// 			max-width: 400px;
// 			margin: 0 auto 2rem;
// 			display: inline-block;
// 		}
// 	}
//
// 	.meta-cta {
// 		@media only screen and (max-width: $screen-sm) {
// 			display: none;
// 		}
//
// 		@media only screen and (min-width: $screen-sm) {
// 			display: initial;
// 			text-align: right;
// 			@include make-sm-column(6);
// 			@include make-md-column(8);
// 		}
// 	}
// }
