.cta {
  text-align: center;
  background: rgba($brand-action,.2);
  padding: 3rem 0 4rem;
  margin-top: 3rem;

  p:last-child {
    margin-bottom: 0;
  }

  p {
    max-width: 800px;
    margin: 1.5rem auto;
  }
}
