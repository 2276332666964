// Variables


$blk: #222222;
$brand-blue-dk: #004F6C;
$brand-blue-lt: #BACDEA;
$brand-tan-lt: #E2DDCB;
$brand-tan-dk: #BEB0A3;
$brand-action: #98C99C;
$brand-radius: 4px;

$use-fadeInUp: true;
