.content-column,.content-rich-text,.cta {
	h1,h2,h3,h4,h5,h6 {
	@include bbg-headers;
	}

	a {
		color: $brand-tan-dk;
		font-weight: bold;
		&:hover {
			text-decoration: none;
			color: darken($brand-tan-dk,10%);
			border-bottom: 1px solid $brand-tan-lt;
		}
	}
}


main {
	.content-column {
		margin: 6rem auto;
		@include bbg-wrapper;
	}

	.pull-text {
		text-align: center;
		h2 {
			font-weight: 200;
			width: 85%;
			max-width: 960px;
			padding: 6rem;
			margin: 3rem auto 0;
			@include callout;

			@media only screen and (min-width: $screen-sm) {
				max-width: 1140px;
			}

		}

		small {
			color: $brand-blue-lt;
			text-transform: uppercase;
		}
	}

}
