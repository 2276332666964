// .video-banner {
// 	position: relative;
// 	text-align: center;
// 	overflow: hidden;
// }
//
// .video-overlay,.video-wrapper {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   display: block;
// }
//
// .video-overlay {
//
// }
//
// .video-wrapper {
//
// }

.video-wrapper {
	position: relative;
	overflow: hidden;
	video {
		width: 100%;
	}

	video::-webkit-media-controls {
	    display:none !important;
	}


}
.video-overlay {
	position: absolute;
	text-align: center;
	color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
	background: rgba($brand-blue-lt,.7);
	display: flex;

	h3 {
		flex: 1;
		align-self: center;
		color: $blk;
	}

	@media only screen and (min-width: $screen-sm) {
		h3 {
			font-size: 4rem;

		}
	}
}
