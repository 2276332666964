.service-detail-list {

	h6 {
		font-weight: bold;
		text-transform: capitalize;
		text-align: center;
		font-size: 2.2rem;
		margin-bottom: 3rem;
	}

	margin-top: 6rem;

	ul {
		list-style-type: none;
		margin: 6rem auto;
		padding: 0;
		@include bbg-wrapper;

		li {
			//text-align: center;
			box-sizing: border-box;
			font-size: 2.2rem;
			font-weight: 300;
			text-transform: capitalize;
			vertical-align: middle;
			background: rgba($brand-blue-lt,.15);
			padding: 1.5rem;
			margin-bottom: 1%;
			border-radius: $brand-radius;
			&:before {
				content: "";
				display: inline-block;
				height: 2.8rem;
				width: 2.8rem;
				margin-right: 8px;
				vertical-align: middle;
				background:{
					image: url('../img/check.svg');
					size: 100% 100%;
				}
			}

		}

		@media only screen and (min-width: $screen-sm) {

			li {
				width: 49.5%;
				display: inline-block;
			}
			li:nth-child(even){
				margin-left: 1%;
			}

		}
	}
}
